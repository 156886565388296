import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css"
import axios from 'axios';
import { useState, useEffect } from 'react';
import { API } from '../../services/api';
import dayjs from 'dayjs'
import Swal from 'sweetalert2';
export default function CalendarCompClient({ onSelectEvent, handleOpenModal }) {


    const localizer = dayjsLocalizer(dayjs)
    const [events, setEvents] = useState([]);
    const [selected, setSelected] = useState();


    const convertDate = (date) => {
        return dayjs.utc(date).toDate();
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API}eventos/`);

                const currentDate = dayjs();
                const twoWeeksLater = currentDate.add(3, 'week');

                const appointments = response.data
                    .filter(appointment => {
                        const startDate = dayjs.utc(appointment.start);
                        return startDate.isBetween(currentDate.startOf('week'), twoWeeksLater.endOf('week'), null, '[]');
                    })
                    .map(appointment => ({
                        ...appointment,
                        start: convertDate(appointment.start),
                        end: convertDate(appointment.end),
                    }));

                setEvents(appointments);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);



    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.ocupado ? 'red' : 'green', // Cambiar a 'red' si ocupado es verdadero
            borderRadius: '0px',
            opacity: 0.8,
            color: 'white',
            border: '1px solid #fff',
            display: 'block'
        };
        return {
            style: style
        };
    };

    const handleSelected = (event) => {
        // Obtener la fecha actual
        const fechaActual = dayjs();

        // Obtener la fecha seleccionada del evento
        const fechaSeleccionada = dayjs(event.start);

        // Obtener la fecha dentro de dos semanas
        const fechaDosSemanas = fechaActual.add(3, 'week');

        if (event.ocupado) {
            Swal.fire({
                icon: 'warning',
                title: 'Horario Ocupado',
                text: 'Lo sentimos, este horario está ocupado, por favor seleccione un horario disponible.',
                showConfirmButton: true,
                didClose: () => {
                    // Recargar la página después de que el usuario cierre la alerta
                    window.location.reload();
                }
            });
        } else if (fechaSeleccionada.isSameOrAfter(fechaActual, 'day') && fechaSeleccionada.isBefore(fechaDosSemanas, 'day')) {
            // Si el evento no está ocupado y la fecha está dentro del rango permitido, proceder con el manejo del evento seleccionado
            setSelected(event);
            onSelectEvent(event);
            handleOpenModal();
        } else {
            // La fecha seleccionada está fuera del rango permitido, mostrar mensaje de SweetAlert
            Swal.fire({
                icon: 'warning',
                title: 'Horario Expirado',
                text: 'Este horario ya no está vigente. Por favor, seleccione uno nuevo.'
            });
        }
    };



    const minTime = dayjs().set('hour', 8).set('minute', 0).set('second', 0).toDate(); // Hora mínima: 8:00 AM
    const maxTime = dayjs().set('hour', 20).set('minute', 59).set('second', 59).toDate(); // Hora máxima: 8:59 PM

    const formats = {

        dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
            const startDate = dayjs(start).format('MMMM YYYY');
            return `${startDate}`;
        },
    };

    const messages = {
        allDay: "Todo el día",
        previous: "Anterior",
        next: "Siguiente",
        today: "Hoy",
        month: "Mes",
        week: "Semana",
        day: "Día",
        date: "Fecha",
        time: "Hora",
        event: "Evento",
        noEventsInRange: "Sin eventos"
    };

    return (

        <div style={{
            height: "85vh",
            width: "90vw",
            margin: "0 auto",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Calendar
                selected={selected}
                onSelectEvent={handleSelected}
                localizer={localizer}
                events={events}
                views={["month", "week"]}
                defaultView="week"
                step={5}
                min={minTime}
                max={maxTime}
                timeslots={2}
                eventPropGetter={eventStyleGetter}
                formats={formats}
                messages={messages}

            />

        </div>
    );
}
